import * as React from 'react';
import { graphql } from 'gatsby';
import Masonry from 'react-masonry-component';
import MasonryCard from '../components/MasonryCard/masonryCard';
import Pagination from '../components/Pagination/pagination';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BlogPostsWrapper, PostRow, PostCol } from './templates.style';

const BlogList = (props: any) => {
	const { data } = props;
	const Posts = data.allMarkdownRemark.edges;
	const { currentPage, numPages } = props.pageContext;
	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages;
	const prevPage = currentPage - 1 === 1 ? '/page/1' : `/page/${(currentPage - 1).toString()}`;
	const nextPage = `/page/${(currentPage + 1).toString()}`;
	const PrevLink = !isFirst && prevPage;
	const NextLink = !isLast && nextPage;

	return (
		<Layout>
			<SEO title={`Page ${currentPage}`} />

			<BlogPostsWrapper>
				<PostRow>
					<Masonry className="showcase">
						{Posts.map(({ node }: any) => {
							return (
								<PostCol key={node.fields.slug}>
									<MasonryCard
										title={node.frontmatter.title || node.fields.slug}
										image={
											node.frontmatter.cover == null ? null : (
												node.frontmatter.cover.childImageSharp.fluid
											)
										}
										url={node.fields.slug}
										date={node.frontmatter.date}
										tags={node.frontmatter.tags}
										readTime={node.fields.readingTime.text}
									/>
								</PostCol>
							);
						})}
					</Masonry>
				</PostRow>

				<Pagination
					prevLink={PrevLink}
					nextLink={NextLink}
					currentPage={`${currentPage}`}
					totalPage={`${numPages}`}
				/>
			</BlogPostsWrapper>
		</Layout>
	);
};

export default BlogList;

export const pageQuery = graphql`
	query($skip: Int!, $limit: Int!) {
		site {
			siteMetadata {
				title
			}
		}
		sitePage {
			path
		}
		allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/blog/" } }
			sort: { fields: [frontmatter___date], order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					excerpt(pruneLength: 300)
					fields {
						slug
						readingTime {
							text
						}
					}
					frontmatter {
						date(formatString: "DD [<span>] MMMM [</span>]")
						title
						protected
						description
						tags
						cover {
							childImageSharp {
								fluid(maxWidth: 1170, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
// ...GatsbySanityImageFluid_withWebp_noBase64
